export interface Tutorial {
  software: string;
  youtubeLink: string;
  link?: string;
}

export const tutorials: Tutorial[] = [
  {
    software: "kajabi",
    youtubeLink: "https://www.youtube.com/embed/GMipOLFDcps",
  },
  {
    software: "wordpress",
    youtubeLink: "https://www.youtube.com/embed/Ah6zBVqjjUE",
  },
  {
    software: "zenler",
    youtubeLink: "https://www.youtube.com/embed/Zum3LjF7ys4",
  },
  {
    software: "elementor",
    youtubeLink: "https://www.youtube.com/embed/R_DDdRRkOyM",
  },
  // {
  //   software: "podia",
  //   youtubeLink: "https://www.youtube.com/embed/",
  // },
  {
    software: "kartra",
    youtubeLink: "https://www.youtube.com/embed/8_b3IOoLK50",
  },
  // {
  //   software: "wix",
  //   youtubeLink: "https://www.youtube.com/embed/",
  // },
  // {
  //   software: "squarespace",
  //   youtubeLink: "https://www.youtube.com/embed/",
  // },
  // {
  //   software: "weebly",
  //   youtubeLink: "https://www.youtube.com/embed/",
  // },
];
