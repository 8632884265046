import React from "react";

export default function Thanks() {
  return (
    <div className="thanks-page">
        <h3>Thanks for signing up!</h3>
        <p>Please check your email (including your promotions, just in case)</p>
        <p>This is what the email should look like:</p>
        <img src="/images/email-example.png" alt="" className="pretty-image" />
        {/* <br /> */}
        {/* <p>Thanks for signing up!</p> */}
        {/* <Link to="/create" className="button">Now let's create</Link> */}
    </div>
  );
}
